<template>
  <div>
    <b-menu>
      <b-menu-list>
        <b-menu-item
          v-if="$store.state.user && $store.state.user.superuser"
          icon="account-group"
          :active="openMenu == 'system'"
          :expanded="openMenu == 'system'"
        >
          <template slot="label" slot-scope="props">
            Customers
            <b-icon
              class="is-pulled-right"
              :icon="props.expanded ? 'menu-down' : 'menu-up'"
            />
          </template>
          <b-menu-item icon="school" label="Organisations" tag="router-link" to="/subscriptions" :active="this.$route.path == '/subscriptions'" />
          <b-menu-item icon="domain" label="Districts / Trusts" tag="router-link" to="/districts" :active="this.$route.path == '/districts'" />
          <b-menu-item icon="account-circle" label="Users" tag="router-link" to="/users" :active="this.$route.path == '/users'" />
          <b-menu-item icon="clock" label="Trials" tag="router-link" to="/trials" :active="this.$route.path == '/trials'" />
          <b-menu-item icon="trophy" label="Champions" tag="router-link" to="/champions" :active="this.$route.path == '/champions'" />
          <!-- <b-menu-item icon="book" label="Dictionary" tag="router-link" to="/dictionary" :active="this.$route.path == '/dictionary'" /> -->

          <b-menu-item icon="email" label="Emails" tag="router-link" to="/emails" :active="this.$route.path == '/emails'" />
          <!-- <b-menu-item icon="download" label="Resources" tag="router-link" to="/resources" :active="this.$route.path == '/resources'" /> -->
          <!-- <b-menu-item icon="package" label="External Curricula" tag="router-link" to="/external_curricula" :active="this.$route.path == '/external_curricula'" />
          <b-menu-item icon="package" label="Curriculum Packs" tag="router-link" to="/packs" :active="this.$route.path == '/packs'" /> -->

          <!-- <b-menu-item icon="gamepad-square-outline" label="Grammar Arcade" tag="router-link" to="/grammar" :active="this.$route.path == '/grammar'" /> -->

          <b-menu-item icon="view-list" label="Event Logs" tag="router-link" to="/event_logs" :active="this.$route.path == '/event_logs'" />
          <b-menu-item icon="alert" label="Reported Content" tag="router-link" to="/reported_questions" :active="this.$route.path == '/reported_questions'" />

          <b-menu-item icon="earth" label="Countries" tag="router-link" to="/countries" :active="this.$route.path == '/countries'" />
          <b-menu-item icon="beehive-outline" label="Hive Games" tag="router-link" to="/hives" :active="this.$route.path == '/hives'" />
          <b-menu-item icon="alpha-d-circle" label="D6 Schools" tag="router-link" to="/d6schools" :active="this.$route.path == '/d6schools'" />
        </b-menu-item>
        <b-menu-item
          v-if="$store.state.user && ($store.state.user.dictionary_editor || $store.state.user.superuser)"
          icon="cog"
          :active="openMenu == 'content'"
          :expanded="openMenu == 'content'"
        >
          <template slot="label" slot-scope="props">
            Content
            <b-icon
              class="is-pulled-right"
              :icon="props.expanded ? 'menu-down' : 'menu-up'"
            />
          </template>
          <b-menu-item icon="book" label="Dictionary" tag="router-link" to="/dictionary" :active="this.$route.path == '/dictionary'" />
          <b-menu-item icon="gamepad-square-outline" label="Grammar Arcade" tag="router-link" to="/grammar" :active="this.$route.path == '/grammar'" />
          <b-menu-item icon="gamepad-square-outline" label="Grammar Nodes" tag="router-link" to="/grammar-nodes" :active="this.$route.path == '/grammar-Nodes'" />
          <b-menu-item icon="download" label="Resources" tag="router-link" to="/resources" :active="this.$route.path == '/resources'" />
          <b-menu-item icon="package" label="Curriculum Packs" tag="router-link" to="/packs" :active="this.$route.path == '/packs'" />
          <b-menu-item icon="package" label="External Curricula" tag="router-link" to="/external_curricula" :active="this.$route.path == '/external_curricula'" />
          <b-menu-item icon="account-circle" label="Avatar Items" tag="router-link" to="/avatar" :active="this.$route.path == '/avatar'" />

          <b-menu-item icon="notebook-plus-outline" label="Quiz Subjects" tag="router-link" to="/quiz_subjects" :active="this.$route.path == '/quiz_subjects'" />
          <b-menu-item icon="alphabetical-variant" label="Letter Tiles Words" tag="router-link" to="/letter_tiles_words" :active="this.$route.path == '/letter_tiles_words'" />
          <b-menu-item icon="alphabetical" label="Phonics Screening" tag="router-link" to="/phonics_screening" :active="this.$route.path == '/phonics_screening'" />
          <b-menu-item icon="microphone" label="Phonemes" tag="router-link" to="/phonemes" :active="this.$route.path == '/phonemes'" />
          <b-menu-item icon="star-circle-outline" label="Featured Quizzes" tag="router-link" to="/featured_quizzes" :active="this.$route.path == '/featured_quizzes'" />
        </b-menu-item>
        <b-menu-item
          v-if="$store.state.user && $store.state.user.superuser"
          icon="cart"
          :active="openMenu == 'merch'"
          :expanded="openMenu == 'merch'"
        >
          <template slot="label" slot-scope="props">
            Merch Store
            <b-icon
              class="is-pulled-right"
              :icon="props.expanded ? 'menu-down' : 'menu-up'"
            />
          </template>
          <b-menu-item
            icon="package-variant-closed"
            label="Products"
            tag="router-link"
            to="/merch-products"
            :active="this.$route.path == '/merch-products'"
          />
          <b-menu-item icon="truck" label="Merch Sales" tag="router-link" to="/merchsales" :active="this.$route.path == '/merchsales'" />
        </b-menu-item>
        <b-menu-item
          v-if="$store.state.user && $store.state.user.superuser"
          icon="currency-gbp"
          :active="openMenu == 'sales'"
          :expanded="openMenu == 'sales'"
        >
          <template slot="label" slot-scope="props">
            Sales
            <b-icon
              class="is-pulled-right"
              :icon="props.expanded ? 'menu-down' : 'menu-up'"
            />
          </template>
          <b-menu-item icon="receipt" label="Invoices" tag="router-link" to="/invoices" :active="this.$route.path == '/invoices'" />
          <b-menu-item
            v-if="$store.state.user.superduperuser"
            icon="cash"
            label="Finance"
            tag="router-link"
            to="/financials"
            :active="this.$route.path == '/financials'"
          />
          <b-menu-item
            v-if="$store.state.user.superuser"
            icon="calendar-blank-multiple"
            label="Subscription Plans"
            tag="router-link"
            to="/subscription-products"
            :active="this.$route.path == '/subscription-products'"
          />
          <b-menu-item
            v-if="$store.state.user.superuser"
            icon="calendar-blank-multiple"
            label="Subscription Migrations"
            tag="router-link"
            to="/subscription-actions"
            :active="this.$route.path == '/subscription-actions'"
          />
          <b-menu-item icon="poll" label="Sales Dashboard" tag="router-link" to="/sales_dashboard" :active="this.$route.path == '/sales_dashboard'" />
          <b-menu-item icon="account-circle" label="Sales Persons" tag="router-link" to="/sales_persons" :active="this.$route.path == '/sales_persons'" />
          <b-menu-item icon="poll" label="Cancellations" tag="router-link" to="/cancellations" :active="this.$route.path == '/cancellations'" />
          <b-menu-item icon="poll" label="Engagement Report" tag="router-link" to="/engagement_reports" :active="this.$route.path == '/engagement_reports'" />
        </b-menu-item>
        <b-menu-item
          v-if="$store.state.user && $store.state.user.superuser"
          icon="chart-line"
          :active="openMenu == 'reports'"
          :expanded="openMenu == 'reports'"
        >
          <template slot="label" slot-scope="props">
            Reports
            <b-icon
              class="is-pulled-right"
              :icon="props.expanded ? 'menu-down' : 'menu-up'"
            />
          </template>
          <b-menu-item icon="alert" label="Non-Compliant Subscriptions" tag="router-link" to="/non-compliants" :active="this.$route.path == '/non-compliants'" />
        </b-menu-item>
      </b-menu-list>
    </b-menu>
  </div>
</template>
<script>
export default {
  name: 'SidebarName',
  computed: {
    openMenu () {
      switch (this.$route.path) {
        case '/districts':
          return 'system'
        case '/subscriptions':
          return 'system'
        case '/users':
          return 'system'
        case '/financials':
          return 'sales'
        case '/invoices':
          return 'sales'
        case '/trials':
          return 'system'
        case '/emails':
          return 'system'
        case '/resources':
          return 'content'
        case '/packs':
          return 'content'
        case '/d6schools':
          return 'system'
        case '/champions':
          return 'system'
        case '/dictionary':
          return 'content'
        case '/letter_tiles_words':
          return 'content'
        case '/phonics_screening':
          return 'content'
        case '/phonemes':
          return 'content'
        case '/external_curricula':
          return 'content'
        case '/quiz_subjects':
          return 'content'
        case '/grammar':
          return 'content'
        case '/grammar-nodes':
          return 'content'
        case '/event_logs':
          return 'system'
        case '/reported_questions':
          return 'system'
        case '/featured_quizzes':
          return 'content'
        case '/countries':
          return 'system'
        case '/hives':
          return 'system'
        case '/merchsales':
          return 'merch'
        case '/merch-products':
          return 'merch'
        case '/subscription-products':
          return 'sales'
        case '/subscription-actions':
          return 'sales'
        case '/sales_dashboard':
          return 'sales'
        case '/sales_persons':
          return 'sales'
        case '/cancellations':
          return 'sales'
        case '/engagement_reports':
          return 'sales'
        case '/non-compliants':
          return 'reports'
        default:
          return ''
      }
    }
  },
  methods: {
    confirmLogout () {
      const c = confirm('Logout?')
      if (c) {
        this.$router.push('/logout')
      }
    }
  }
}
</script>
<style>
  /* override default */

  .sidebar-menu>li>a {
    padding: 12px 15px 12px 15px;
  }

  .sidebar-menu li.active>a>.fa-angle-left, .sidebar-menu li.active>a>.pull-right-container>.fa-angle-left {
    animation-name: rotate;
    animation-duration: .2s;
    animation-fill-mode: forwards;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-90deg);
    }
  }

  .pageLink  {
    font-size: 18px;
  }

  .sidebar-menu>li>a {
    padding: 12px 10px 12px 10px;
    text-align: center;
  }

  .pageLink .page {
    margin-left: 10px;
  }

  /* .menu-label {
    margin-left: 5px;
  } */

  .menu {
    margin: 0 5px;
  }

  .sidebar-menu {
    display: none;
  }

  .sidebar-collapse .sidebar-menu {
    display: block;
  }

  .sidebar-collapse .menu {
    display: none;
  }

</style>
